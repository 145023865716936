import * as React from "react"
type Props = {
    height: string
}

const AppStore = ({ height }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 582.1 514.22" height={height} className="LinkIcon">
        <path d="m290 46.36 16.2-28c10-17.5 32.3-23.4 49.8-13.4s23.4 32.3 13.4 49.8l-156.1 270.2h112.9c36.6 0 57.1 43 41.2 72.8h-331c-20.2 0-36.4-16.2-36.4-36.4s16.2-36.4 36.4-36.4h92.8L248 119.06l-37.1-64.4c-10-17.5-4.1-39.6 13.4-49.8 17.5-10 39.6-4.1 49.8 13.4l15.9 28.1Zm-140.4 388.9-35 60.7c-10 17.5-32.3 23.4-49.8 13.4s-23.4-32.3-13.4-49.8l26-45c29.4-9.1 53.3-2.1 72.2 20.7ZM451 325.16h94.7c20.2 0 36.4 16.2 36.4 36.4s-16.2 36.4-36.4 36.4h-52.6l35.5 61.6c10 17.5 4.1 39.6-13.4 49.8-17.5 10-39.6 4.1-49.8-13.4-59.8-103.7-104.7-181.3-134.5-233-30.5-52.6-8.7-105.4 12.8-123.3 23.9 41 59.6 102.9 107.3 185.5Z" data-name="Capa 1" />
    </svg>
)
export default AppStore
